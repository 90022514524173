.faqs {
  background-color: #FFFFFF;
  border: solid 1px #CED0D2;
  border-radius: 8px;
  max-width: 1000px;
  margin: 24px auto;
  padding: 24px 50px;

  .h2 {
    color: #424C53;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 600px) {  
  .faqs {
    border: none;
    border-radius: 0;
    padding: 24px 16px;
  }
}