.Banner {
  margin-bottom: 50px;
  position: relative;

  .defaultBackground {
    background: linear-gradient(to left, #0B4D89, #2BC9B4);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    height: 200px;

    .bannerImage {
      background-position: center center;
      background-repeat: no-repeat;
      background-size: auto 100%;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      height: 100%;
    }
  }

  .logoImg {
    background-color: #FFFFFF;
    border-radius: 8px;
    border: solid 1px #CED0D2;
    height: 150px;
    position: absolute;
    top: 100px;
    left: 50px;
    width: 150px;

    img {
      border-radius: 8px;
      min-height: 148px;
      max-height: 150px;
      min-width: 148px;
      max-width: 150px;

      &[alt]:after {
        padding-top: 63px;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .Banner {
    .defaultBackground {
      .bannerImage {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }

    .logoImg {
      left: 16px;
    }
  }
}