.label {
    background-color: #FFFFFF;
    border-radius: 25px;
    border: solid 1px #ced0d2;
    color: #424c53;
    cursor: pointer;
    float: left;
    font-family: "Nunito Sans";
    font-size: 16px;
    font-weight: 600;
    margin-right: 8px;
    padding: 5px 14px;
    text-align: center;

    svg {
        width: 16px;
        height: 16px;
        padding: 2px;
        margin-left: 8px;
        opacity: 0.5;
    }

    &.selected {
        background-color: #424c53;
        border-color: #424c53;
        color: #FFFFFF;
    }
}