.option {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.switch {
  vertical-align: middle;
  margin: 5px 0;
}
