.trustCenter {
  background-color: #F4F5F5;
  padding: 40px 0;
}

@media only screen and (max-width: 600px) {  
  .trustCenter {
    padding: 0 0;
  }
}
