.Badge {
  font-size: 12px;
  font-weight: 700;
  padding-right: 21px;
  text-align: center;

  .icon {
    height: 60px;
    width: 60px;

    svg {
      border-radius: 4px;
    }
  }
}