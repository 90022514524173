.faq {
  background-color: #F4F5F5;
  border-top: solid 1px #CED0D2;
  border-left: solid 1px #CED0D2;
  border-right: solid 1px #CED0D2;
  cursor: pointer;
  font-size: 16px;
  padding: 12px 16px;
  width: 100%;
}

.faqExpanded {
  background-color: #FFFFFF;   
  border-top: solid 1px #CED0D2;
  border-left: solid 1px #CED0D2;
  border-right: solid 1px #CED0D2;
  cursor: pointer;
  font-size: 16px;
  padding: 12px 16px;
  width: 100%;
}