.SecuritySummaries {
  padding: 24px 50px;

  .h2 {
    color: #424C53;
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 600px) {  
  .SecuritySummaries {
    padding: 24px 16px;
  }
}