.dropdown {
    position: absolute;
    right: 352px;
}

.dropdownContent {
    background-color: #ffffff;
    box-shadow: 0px 12px 24px 0px #00000080;
    padding: 0;
    width: 340px;

    input {
        margin-right: 5px;
    }
}