.requestFormEdit {
  margin-bottom: -30px;

  .columns {
    margin: auto;
    max-width: 1000px;
  }

  .card {
    background-color: #EDEEEF;
    border: solid 1px #ced0d2;
    border-radius: 8px;
    padding: 20px;
  }

  .published {
    background-color: #FFFFFF;
    border: solid 1px #ced0d2;
    border-radius: 8px;
    font-size: 14px;
    padding: 10px 20px;
  }
}