.separator {
    width: 20px;
}

.card {
    background-color: #F4F5F5;
    border-radius: 8px;
    height: 385px;
}

.cardInfo {
    padding: 50px 0;
}
