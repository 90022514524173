.Title {
  padding: 24px 50px;

  .h2 {
    color: #18242D;
    font-size: 32px;
    font-weight: 700;
  }
}

@media only screen and (max-width: 600px) {  
  .Title {
    padding: 24px 16px;
  }
}