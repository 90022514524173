.requestForm {
  background-color: #FFFFFF;
  border: solid 1px #CED0D2;
  border-radius: 8px;
  max-width: 1000px;
  margin: auto;
  padding: 50px 100px;
  width: 600px;

  .banner {
    img.banner {
      border-radius: 4px;
      height: 200px;
    }
  }
}

.separator {
  margin: 35px auto 15px;
  border-bottom: solid 1px #ced0d2;
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .requestForm {
    border: none;
    border-radius: 0;
  }
}

.requestFormLogo {
  margin: auto;
}