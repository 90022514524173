.Packages {
  margin-top: 5px;

  .h2 {
    color: #424C53;
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 15px;
  }
}

.requestPackageTosSecondLine {
  display: block;
  font-size: 14px;
  margin-top: -8px;
  padding-left: 35px;
}