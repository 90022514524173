.Subprocessors {
  padding: 24px 50px;

  .h2 {
    color: #424C53;
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 10px;
  }

  table {
    border-collapse: initial;
    border-radius: 8px;
    width: 100%;

    th, td {
      border-bottom: solid 1px #CED0D2;
      border-right: solid 1px #CED0D2;
      padding: 12px 16px;
      width: 33%;
    }

    th {
      background-color: #F4F5F5;
      border-top: solid 1px #CED0D2;
    }
  }

  table tr:first-child th:first-child {
    border-top-left-radius: 8px;
    border-left: solid 1px #CED0D2;
  }

  table tr:first-child th:last-child {
    border-top-right-radius: 8px;
  }  

  table tr td:first-child {
    border-left: solid 1px #CED0D2;
  }  

  table tr:last-child td:first-child {
    border-bottom-left-radius: 8px;
  }
      
  table tr:last-child td:last-child {
    border-bottom-right-radius: 8px;
  }
}

.SubprocessorsOverflow {
  overflow-y: auto;
}

@media only screen and (max-width: 600px) {  
  .Subprocessors {
    padding: 24px 0 24px 16px;
  }
}
