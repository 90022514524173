.Packages {
  padding: 24px 50px;

  .h2 {
    color: #424C53;
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 15px;
  }

  .columns {
    .column:first-child {
      padding-right: 5px;
    }
    .column:last-child {
      padding-left: 5px;
    }
  }
}

.requestPackageTosSecondLine {
  display: block; 
  font-size: 14px; 
  margin-top: -8px; 
  padding-left: 35px;
}

@media only screen and (max-width: 600px) {  
  .Packages {
    padding: 24px 16px;
  }

  .requestPackageFormWrapper {
    margin: -24px;
  }

  .requestPackageTosSecondLine {
    display: inline !important;
    padding-left: 3px !important;
  }

  .modalCard {
    border-radius: 0;
    height: 100%;
    max-height: 100%;
  }

  .modalCardHeader { 
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: 12px 6px 12px 16px;
  }

  .modalCardFooter { 
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding: 20px 16px;
  }
}
