.trustCenter {
    background-color: #FFFFFF;
    border: solid 1px #CED0D2;
    border-radius: 8px;
    max-width: 1000px;
    margin: auto;
    padding-bottom: 20px;

    .banner {
        img.banner {
            border-radius: 4px;
            height: 200px;
        }
    }
}

@media only screen and (max-width: 600px) {  
  .trustCenter {
        border: none;
        border-radius: 0;
  }
}
