.banner {
    align-items: center;
    background-color: #0075e0;
    color: #ffffff;
    display: flex;
    font-size: 18px;
    font-weight: 600;
    height: 48px;
    justify-content: space-between;
    overflow: hidden;
    position: fixed;
    text-align: center;
    transition: all 700ms;
    width: 100%;
    z-index: 1;

    a {
        color: #ffffff;

        &:hover {
            text-decoration: underline;
        }
    }
}

.dismissNotification {
    width: 50px;

    a {
        background-color: transparent;
        transition: all 700ms;

        &::before,
        &::after {
            background-color: #ffffff;
        }
    }
}