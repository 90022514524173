.Package {
  font-size: 16px;
  font-weight: 700;
  margin-left: 32px;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  input {
    margin-right: -45px;
    z-index: 10;
  }

  label {
    height: 72px;
    padding: 0 0 0 60px;
    justify-content: left;
    font-size: 14px;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;

    span {
      width: 340px;
      white-space: pre-wrap;
      max-height: 70px;
    }

    b {
      font-size: 16px;
    }
  }
}