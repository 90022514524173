.selectWrapper {
    position: relative;

    select {
        appearance: none;
        background-color: #f4f5f5;
        border: 0;
        border-radius: 18px;
        height: 40px;
        margin: -6px 0 0 15px;
        outline-width: 0;
        padding: 10px 40px 10px 20px;
    }

    &:after {
        content: ' ';
        position: absolute;
        right: 15px;
        top: 12px;
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid #000;
    }
}